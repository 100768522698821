import React, { useState } from 'react'
import { Icon } from '@iconify/react'

import './Builder.css'
const parts = [
  {
    type: 'Head',
    icon: 'game-icons:bird-mask',
    options: [
      'Superman Curl',
      'Dunce',
      'Backwards Cap',
      'Green Beanie',
      'Yellow Beanie',
      'Orange Beanie',
      'Halo',
      'Fire',
      'Witch',
      'Viking',
      'None'
    ]
  },
  {
    type: 'Eyes',
    icon: 'icon-park-solid:eyes',
    options: [
      'Pit Vipers',
      'Square Glasses',
      'Dead',
      'Dead Pepe',
      'Sunglasses',
      'Normal',
      'Glasses',
      'Eye Patch',
      'Lasers',
      'Tired'
    ]
  },
  {
    type: 'Beak',
    icon: 'mingcute:mouth-fill',
    options: [
      'Worm',
      'Tongue Out',
      'Open',
      'Curved',
      'Straw',
      'JAY Holder',
      'Money Bags',
      'Normal',
      'Rainbow',
      'Gold'
    ]
  },
  {
    type: 'Necks',
    icon: 'icon-park-solid:diamond-necklace',
    options: [
      'Heart Tattoo',
      'Flower Lei',
      'Binoculars',
      'Gold Chain',
      'Chain',
      'Necklace',
      'JAY Necklace',
      'None'
    ]
  },
  {
    type: 'Hand',
    icon: 'game-icons:bird-claw',
    options: ['Frying Egg', 'Money Bag', 'None']
  },
  {
    type: 'Clothes',
    icon: 'tabler:shirt-filled',
    options: ['Big Testies', 'Sheriff Badge', 'Abs', 'Suit', 'None']
  },
  {
    type: 'Skin',
    icon: 'gis:color',
    options: [
      'Burnt',
      'Ghost',
      'Angel',
      'Gold',
      'Pink',
      'White',
      'Parrot',
      'Toucan',
      'Devil',
      'Trippy',
      'Zombie',
      'Normal'
    ]
  },
  {
    type: 'Text',
    icon: 'ph:text-aa-bold',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
  },
  {
    type: 'Wings',
    icon: 'game-icons:liberty-wing',
    options: [
      'Flames',
      'Green Candles',
      'Normal',
      'Skeleton',
      'Broken',
      'Metal',
      'Devil',
      'Angel',
      'None'
    ]
  },
  {
    type: 'Backgrounds',
    icon: 'fluent:video-background-effect-16-filled',
    options: [
      'Stars',
      'Tree',
      'Vault',
      'Money Printer',
      'Crime Scene',
      'Green Candles',
      'Money Bags',
      'Wads of Cash'
    ]
  }
]
const Builder = () => {
  const [part, setPart] = useState('Head')
  const [options, setOptions] = useState({
    Head: 'None',
    Eyes: 'Normal',
    Beak: 'Open',
    Necks: 'None',
    Hand: 'None',
    Clothes: 'None',
    Skin: 'Normal',
    Text: 'None',
    Wings: 'Normal',
    Backgrounds: 'Stars'
  })

  const stateObject = (val, update) => {
    let returnObj = {}
    returnObj[val] = update
    return returnObj
  }
  return (
    <section className="builder">
      <section className="contain">
        <div className="head">BIRB BUILDOOR</div>
        <section className="parts">
          <div className="title">{part}</div>
          {parts
            .find(p => p.type == part)
            .options.map(o => (
              <div
                key={o}
                className={options[part] == o ? 'selected' : ''}
                onClick={() =>
                  setOptions(s => {
                    const k = stateObject(part, o)
                    return { ...s, ...k }
                  })
                }
              >
                {o}
              </div>
            ))}
        </section>
        <section className="birb">
          {parts.map(p => (
            <div
              key={p.type}
              className="icon"
              style={{ color: part === p.type ? 'grey' : 'inherit' }}
              onClick={() => setPart(p.type)}
            >
              <Icon icon={p.icon} />
            </div>
          ))}
          <div className="bg"></div>
          {Object.keys(options)
            .reverse()
            .map((item, _index) => {
              const index = 10 - _index
              console.log(index)
              const url =
                'img/jaypeggerz/' +
                item +
                '/' +
                index +
                '-' +
                (parts[index - 1].options.findIndex(p => p === options[item]) +
                  1) +
                '.png'
              console.log(item)
              if (item === 'Text' || options[item] === 'None') return <></>
              else
                return (
                  <>
                    <img className="overlay" alt={item} src={url} />{' '}
                    {item === 'Skin' && (
                      <img
                        className="overlay"
                        alt={item}
                        src={'img/jaypeggerz/Skin/lineart.png'}
                      />
                    )}
                  </>
                )
            })}
        </section>
      </section>
    </section>
  )
}

export default Builder
